/**
 * 校验方法
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ["admin", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}

export function checkMobile(phone) {
  var msg = "";
  var telStr = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
  if (!telStr.test(phone)) {
    msg = "手机号码输入不规范";
  } else {
    msg = "";
  }
  return msg;
}
//大写字母、小写字母、数字、特殊字符 满足其中三种即可
export function checkStrong(value) {
  var strength = 0;
  if (value.length > 5 && value.match(/[\da-zA-Z]+/)) {
    if (value.match(/\d+/)) {
      strength++;
    }
    if (value.match(/[a-z]+/)) {
      strength++;
    }
    if (value.match(/[A-Z]+/)) {
      strength++;
    }
    if (value.match(/[!@*$-_()+=&￥]+/)) {
      strength++;
    }
  }
  if (strength >= 2) {
    return true;
  }
  return false;
}
