<template>
  <!-- <div class="set-pwd">
    <div class="set-pwd-title">设置登陆密码</div>
    <div class="set-pwd-con">
      <div class="set-pwd-title">设置新的登录密码</div>
      <div class="set-pwd-info">密码8-20位，至少包含字母/数字两种组合</div>
      <div class="set-pwd-text">
        <input
          type="password"
          v-model="init_pwd"
          @blur="checkInitPwd"
          placeholder="输入登录密码"
        />
      </div>
      <div class="set-pwd-two">
        <input
          type="password"
          v-model="new_pwd"
          @blur="checkResetPwd"
          placeholder="再次登录密码"
        />
      </div>
      <div class="set-pwd-info">设置密码可以和家人共享优惠</div>
      <div class="set-pwd-btn">
        <Button type="default" @click="changePwd" block>下一步</Button>
      </div>
    </div>
  </div> -->
  <div class="setPwd">
    <!-- <div class="set-pwd-text">
      <label for="">当前密码</label>
      <input
        type="password"
        v-model="old_pwd"
        placeholder="当前密码"
      />
      </div> -->
      <div class="set-pwd-text">
        <label for="">新密码</label>
      <input
        type="password"
        v-model="new_pwd"
        @blur="checkInitPwd"
        placeholder="8-20位字母/数字两种组合"
      />
      </div>
      <div class="set-pwd-text set-pwd-new">
        <label for="">再次输入</label>
      <input
        type="password"
        v-model="init_pwd"
        @blur="checkResetPwd"
        placeholder="再次输入新密码"
      />
      </div>
      <div class="set-pwd-btn" v-if="init_pwd&&new_pwd">
        <Button type="default" @click="changePwd" block>保存</Button>
      </div>
      <div class="set-pwd-btn" v-else style="opacity: .3">
        <Button type="default" @click="$toast('请输入全部密码')" block>保存</Button>
      </div>
  </div>
</template>
<script>
import Setting from "@api/setting"
import { Button } from "vant";
import UserInfo from "@api/user";
import { checkStrong } from "@/utils/validate";
export default {
  components: {
    Button
  },
  data() {
    return {
      old_pwd: "",
      init_pwd: "",
      new_pwd: "",
      status: false
    };
  },
  methods: {
    checkInitPwd() {
      if (!checkStrong(this.new_pwd)) {
        this.$toast("密码8-20位，至少包含字母/数字两种组合");
      } else {
        this.status = true;
      }
    },
    checkResetPwd() {
      if (
        this.new_pwd != "" &&
        this.new_pwd != this.init_pwd
      ) {
        this.$toast("密码不一致");
        this.status = false;
      } else {
        this.status = true;
      }
    },
    async submit() {
      try {
        const res = await Setting.verifyPass(this.old_pwd)
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    async changePwd() {
      try {
        if (this.status) {
          const res = await UserInfo.setVipPwd(this.new_pwd, this.init_pwd);
          if (res.code == 200) {
            this.$toast(res.msg);
            this.$router.push("/vip_userInfo");
          }
        } else {
          this.$toast("密码输入有误，请重新输入");
        }
      } catch (error) {
        this.$toast(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin inputPla($color: #333333) {
  // 定义 mixin 并设置默认值 5px
  input::-webkit-input-placeholder {
    color: $color;
  }
  input::-moz-input-placeholder {
    color: $color;
  }
  input::-ms-input-placeholder {
    color: $color;
  }
}
.setPwd {
   width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #f1f1f1;
  padding: 16px 18px;

  .set-pwd-text,
  .set-pwd-two {
    width: 96%;
    padding-left: 8px;
    height: 50px;
    line-height: 50px;
    margin: 20px auto 0;
    border-radius: 6px;
    @include inputPla(#868686);
    background: rgba(255, 255, 255, 1);
    display: flex;
    input {
      display: inline-block;
      flex: 1;
      height: 100%;
      border: none;
      padding-left: 15px;
      color: #000;
      background: none;
    }
  }

  .set-pwd-new {
    margin-top: 0;
    position: relative;
  }
  .set-pwd-new::after {
    position: absolute;
    width: 96%;
    content: "";
    height: 1px;
    background: rgba(167, 167, 167, 0.5);
  }
  .set-pwd-btn {
    width: 40%;
    margin-top: 20px;
    height: 40px;
    margin: 20px auto;

    .van-button {
      background: rgb(253, 43, 6);
      color: #fff;
      border-radius: 8px;
    }
  }
}


// .set-pwd {
//   width: 100%;
//   height: 100vh;
//   overflow: hidden;
//   background: #ffffff;
//   .set-pwd-title {
//     width: 100%;
//     height: 50px;
//     text-align: center;
//     line-height: 50px;
//     font-size: 18px;
//     color: #333333;
//     font-weight: 500;
//   }
//   .set-pwd-con {
//     width: 90%;
//     height: 500px;
//     margin: 20px auto;
//     display: flex;
//     padding-top: 64px;
//     flex-direction: column;
//     align-items: center;
//     background: url(../../../../public/static/images/home/pwd.png) no-repeat;
//     background-size: cover;
//     .set-pwd-title,
//     .set-pwd-info {
//       width: 100%;
//       text-align: center;
//       font-size: 24px;
//       color: #ffffff;
//     }
//     .set-pwd-info {
//       font-size: 14px;
//       margin-top: 24px;
//     }
//     .set-pwd-btn {
//       width: 60%;
//       margin-top: 20px;
//       height: 40px;
//     }
//     .set-pwd-text,
//     .set-pwd-two {
//       width: 88%;
//       height: 50px;
//       margin: 38px auto 0;
//       border-radius: 6px;
//       @include inputPla(#ffdfdf);
//       background: rgba(255, 255, 255, 0.2);
//       input {
//         display: inline-block;
//         width: 100%;
//         height: 100%;
//         border: none;
//         padding-left: 20px;
//         color: #ffffff;
//         background: none;
//       }
//     }
//     .set-pwd-two {
//       margin: 10px auto;
//       background: #ffffff;
//       @include inputPla();
//       input {
//         color: #333333;
//       }
//     }
//   }
// }
</style>
